import { node } from '@/plugins/axios'

export const getChecks = async params => {
  try {
    const { data } = await node.get('/me/autocheck', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getMotorCheck = async params => {
  try {
    const { data } = await node.get('/me/motorcheck', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getVpp = async params => {
  try {
    const { data } = await node.get('/me/vpp', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getCompanion = async params => {
  try {
    const { data } = await node.get('/me/companion', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getAutoCheckValuation = async params => {
  try {
    const { data } = await node.get('/me/autocheck/valuation', { params })
    return data
  } catch (err) {
    throw err
  }
}

export const getBranchUsage = async query => {
  try {
    const { data } = await node.get('me/branch-usage', { params: { ...query } })
    return data
  } catch (err) {
    throw err
  }
}

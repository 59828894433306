import { pick } from 'lodash'
const $experian = process.env.VUE_APP_TYPE === 'experian'

export default {
  token: (state) => state.jwt?.token,
  isGuest: (state) => !state.user?.id,
  isUser: (state) => !!state.user?.id,
  user: (state) => pick(state.user, ['name', 'email', 'id']),
  name: (state) => state.user?.name || 'user',
  initials: (state, { name }) => {
    const names = name.split(' ')
    return names.map((v) => v[0]).join('')
  },
  isAdmin: (state) => state.user?.superUser,
  isBranchAdmin: (state) => state.user?.organisations[0].isBranchAdmin,
  branch: (state) => state.user?.organisations[0].organisation,
  isVerified: (state, { branch }) => !!branch?.terms?.cazana,
  isExperian: (state, { branch }) => !!branch?.experianBranchId,
  isExperianVerified: (state, { branch }) => !!branch?.terms?.experian,
  trials: (state, { branch }) => branch?.trials || [],
  permissions: (state, { branch }) => branch?.permissions || {},
  franchises: (state, { branch }) =>
    branch?.franchises.map(({ franchise }) => franchise),
  vmcAccounts: (state, { branch }) =>
    branch?.franchises.flatMap((f) =>
      f.vmcAccountNumber && f.franchise ? [f.franchise] : []
    ),
  isCompanionUser: (state, { permissions }) => permissions?.vehicleImage,
  hasVmc: (state, { permissions, vmcAccounts }) =>
    permissions?.vmc && vmcAccounts[0],
  hasAutoCheck: (state, { permissions }) =>
    permissions?.provenance === 'autocheck',
  hasMotorCheck: (state, { permissions }) =>
    permissions?.provenance === 'motorcheck',
  hasAutoCheckValuation: (state, { permissions }) =>
    $experian && !!permissions?.autocheckValuation,
  hasAutoCheckBatch: (state, { permissions }) => permissions?.batchAutocheck,
  hasFutureDatedValuations: (state, { permissions }) =>
    permissions?.futureDatedValuations,
  branchHasAdmin: (state, { permissions }) => permissions?.branchAdmin,
  hasMulti: (state, { permissions }) => permissions?.multi,
  hasMultiSummary: (state, { permissions }) => permissions?.multiSummary,
  hasValuationExplorer: (state, { permissions }) =>
    permissions?.valuationExplorer,
  hasStockview: (state, { permissions, sellers, isAdmin }) =>
    permissions?.stockview && (sellers.length > 0 || isAdmin),
  isIntegration: (state, { permissions }) => permissions?.static,
  isOnTrial: (state, { trials }) => {
    return trials.some(trial => trial.active)
  },
  hasCazanaValues: (state, getters) => {
    const { isOnTrial, permissions, isExperianVerified, isExperian } = getters

    const experianPerm =
      permissions.cazanaValues && isExperian && isExperianVerified
    const cazanaPerm = !isExperian && permissions.cazanaValues
    return isOnTrial || experianPerm || cazanaPerm
  },
  isInsurer: (state, { permissions }) => permissions?.valuationDateAdjust,
  dealerIds: (state, { organisation }) => {
    const ids = organisation?.dealers
    return ids ? ids.map(({ dealerId }) => dealerId) : []
  },
  sellers: (state, { branch }) => branch?.sellers,
  credit: (state, { branch }) => branch?.credit || 0,
  organisation: (state, { branch }) => branch?.organisation,
  hasCustomerReference: (state, { permissions }) =>
    permissions?.customerReference
}

import { isRefreshable, logOut } from 'modules/auth/services'

import axios from 'axios'
import store from '@/store'
import router from '@/router'

export const node = axios.create({
  baseURL: process.env.VUE_APP_NODE_URL,
  timeout: 10000,
  headers: {
    'X-ENVIRONMENT': process.env.VUE_APP_MODE || 'unknown'
  }
})

export const guest = axios.create({
  baseURL: process.env.VUE_APP_GUEST_URL,
  timeout: 10000,
  headers: {
    'X-ENVIRONMENT': process.env.VUE_APP_MODE || 'unknown'
  }
})

export const cz = axios.create({
  baseURL: process.env.VUE_APP_CZ_URL
})

export const multi = axios.create({
  baseURL: process.env.VUE_APP_MULTI_URL,
  timeout: 10000,
  headers: {
    'X-ENVIRONMENT': process.env.VUE_APP_MODE || 'unknown'
  }
})

export const stockview = axios.create({
  baseURL: process.env.VUE_APP_STOCKVIEW_URL,
  timeout: 10000,
  headers: {
    'X-ENVIRONMENT': process.env.VUE_APP_MODE || 'unknown',
    }
})

export const pdf = axios.create({
  baseURL: process.env.VUE_APP_PDF_URL,
  timeout: 10000,
})

export const explorer = axios.create({
  baseURL: `${process.env.VUE_APP_NODE_URL}/explorer`,
  timeout: 45000
})

/**
 * Stream downloader
 */
export const stream = axios.create({
  baseURL: process.env.VUE_APP_NODE_URL,
  method: 'GET',
  responseType: 'blob'
})

export const getAddress = axios.create({
  baseURL: 'https://api.getAddress.io/find',
  method: 'GET'
})

const onReq = async config => {
  const excluded = /refresh|login|password/.test(config?.url)

  if (excluded) {
    return config
  }

  if (isRefreshable(store.state.auth)) {
    try {
      await store.dispatch('auth/refresh')
    } catch {
      return {
        ...config,
        cancelToken: new axios.CancelToken(cancel => (logOut(), cancel()))
      }
    }
  }

  return config
}

const onResError = error => {
  const url = error.response?.config.url
  const status = error.response?.status
  const excluded = /refresh|login|password/.test(url)

  if (!excluded) {
    switch (status) {
      case 401:
        logOut()
        break
      case 403:
        router.push('/')
        break
    }
  }

  return Promise.reject(error)
}

// Interceptors
node.interceptors.response.use(response => response, onResError)
node.interceptors.request.use(onReq, err => Promise.reject(err))

const client = process.env.VUE_APP_TYPE === 'experian' ? 'experian' : 'cazana'

// Guest Authorization
guest.defaults.headers.common['X-CZN'] =
  '5ab3cc97a8d11a00017332a7a609b760ad0147e0a12295c85fabe703'
guest.defaults.headers.common['X-CLIENT'] = client
node.defaults.headers.common['X-CLIENT'] = client
stockview.defaults.headers.common['X-CLIENT'] = client

export default node
